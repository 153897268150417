import axios from 'axios';
import {
  ADD_VISA,
  GET_VISAS,
  VISA_LOADING,
  GET_ERRORS,
  DELETE_VISA,
} from '../actionTypes/actionTypes';

export const addVisa = (userData) => (dispatch) => {
  axios
    .post('https://ethioumrah.vohealth.org/api/visa/new-visa', userData)
    .then((res) => {
      dispatch({
        type: ADD_VISA,
        payload: res.data,
      });
    })
    .catch((err) => {
      let message = typeof err.response != 'undefined' ? err.response.data : {};
      dispatch({
        type: GET_ERRORS,
        payload: message,
      });
    });
};

export const visaOnreview = (id) => (dispatch) => {
  dispatch(setVisaLoading());
  axios
    .get(`https://ethioumrah.vohealth.org/api/visa/on-review/${id}`)
    .then((res) => {
      dispatch(getVisas());
    })
    .catch((err) => {
      let message = typeof err.response != 'undefined' ? err.response.data : {};
      dispatch({
        type: GET_ERRORS,
        payload: message,
      });
    });
};

export const getMyVisas = (id) => (dispatch) => {
  dispatch(setVisaLoading());
  axios
    .get(`https://ethioumrah.vohealth.org/api/visa/${id}`)
    .then((res) => {
      dispatch({
        type: GET_VISAS,
        payload: res.data,
      });
    })
    .catch((err) => {
      let message =
        typeof err.response !== 'undefined' ? err.response.data : null;
      dispatch({
        type: GET_ERRORS,
        payload: message,
      });
    });
};

export const visaApprove = (id) => (dispatch) => {
  axios
    .get(`https://ethioumrah.vohealth.org/api/visa/approve/${id}`)
    .then((res) => {
      dispatch(getVisas());
    })
    .catch((err) => {
      let message = typeof err.response != 'undefined' ? err.response.data : {};
      dispatch({
        type: GET_ERRORS,
        payload: message,
      });
    });
};

export const uploadReciept = (recieptData) => (dispatch) => {
  axios
    .post(
      `https://ethioumrah.vohealth.org/api/user/upload-reciept`,
      recieptData
    )
    .then((res) => {
      dispatch(getMyVisas());
    })
    .catch((err) => {
      let message = typeof err.response != 'undefined' ? err.response.data : {};
      dispatch({
        type: GET_ERRORS,
        payload: message,
      });
    });
};

export const uploadVisa = (visaData) => (dispatch) => {
  axios
    .post(`https://ethioumrah.vohealth.org/api/admin/upload-visa`, visaData)
    .then((res) => {
      dispatch(getVisas());
    })
    .catch((err) => {
      let message = typeof err.response != 'undefined' ? err.response.data : {};
      dispatch({
        type: GET_ERRORS,
        payload: message,
      });
    });
};
export const visaReject = (userData) => (dispatch) => {
  dispatch(setVisaLoading());
  axios
    .get(
      `https://ethioumrah.vohealth.org/api/visa/reject/${userData.id}/${userData.newReason}`
    )
    .then((res) => {
      dispatch(getVisas());
    })
    .catch((err) => {
      let message = typeof err.response != 'undefined' ? err.response.data : {};
      dispatch({
        type: GET_ERRORS,
        payload: message,
      });
    });
};

export const getVisas = () => (dispatch) => {
  dispatch(setVisaLoading());
  axios
    .get('https://ethioumrah.vohealth.org/api/admin/visas')
    .then((res) => {
      dispatch({
        type: GET_VISAS,
        payload: res.data,
      });
    })
    .catch((err) => {
      let message =
        typeof err.response !== 'undefined' ? err.response.data : null;
      dispatch({
        type: GET_ERRORS,
        payload: message,
      });
    });
};

export const getSearchVisas = (searchQuery) => (dispatch) => {
  dispatch(setVisaLoading());
  axios
    .get(
      `https://ethioumrah.vohealth.org/api/admin/search-visas/${searchQuery}`
    )
    .then((res) => {
      dispatch({
        type: GET_VISAS,
        payload: res.data,
      });
    })
    .catch((err) => {
      let message =
        typeof err.response !== 'undefined' ? err.response.data : null;
      dispatch({
        type: GET_ERRORS,
        payload: message,
      });
    });
};

export const deleteVisa = (id) => (dispatch) => {
  axios
    .delete('https://ethioumrah.vohealth.org/api/visa/delete-visa', id)
    .then((res) => {
      dispatch({
        type: DELETE_VISA,
        payload: id,
      });
    })
    .catch((err) => {
      let message =
        typeof err.response !== 'undefined' ? err.response.data : null;
      dispatch({
        type: GET_ERRORS,
        payload: message,
      });
    });
};

export const setVisaLoading = () => {
  return {
    type: VISA_LOADING,
  };
};
