import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getMyVisas, uploadReciept } from '../actions/visaActions';
import UserHeader from './UserHeader';
import PendingRequestsListItem from './PendingRequestsListItem';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import IconButton from '@mui/material/IconButton';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { styled } from '@mui/material/styles';
import { StyledTooltip } from './Drawer';
import LoadingSkeleton from './loadingSkeleton';

const Input = styled('input')({
  display: 'none',
});
function UserDashboardContent() {
  const navigate = useNavigate();
  const { isAuthenticated, current_user } = useSelector((state) => state.auth);
  const { visas, loading } = useSelector((state) => state.visa);
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [tabValue, setTabValue] = useState(1);
  const [id, setId] = useState('');
  const [fname, setFname] = useState('');
  const [mname, setMname] = useState('');
  const [lname, setLname] = useState('');
  const [nationality, setNationality] = useState('');
  const [dob, setDob] = useState('');
  const [gender, setGender] = useState('');
  const [mahpassportno, setMahPassportno] = useState('');
  const [passportno, setPassportno] = useState('');
  const [doi, setDoi] = useState('');
  const [doe, setDoe] = useState('');
  const [passportFile, setPassportFile] = useState('');
  const [portraitFile, setPortraitFile] = useState('');
  const [recieptFile, setRecieptFile] = useState('');
  const [visaFile, setVisaFile] = useState('');
  const [visano, setVisano] = useState('');
  const [visadoe, setVisadoe] = useState('');
  const [newRecieptFile, setNewRecieptFile] = useState(null);
  const [date, setDate] = useState('');
  const [status, setStatus] = useState('');
  const [reason, setReason] = useState('');
  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/');
    }
    dispatch(getMyVisas(current_user.id));
  }, [isAuthenticated, navigate, current_user.id, dispatch]);
  function viewDetail(
    id,
    fname,
    mname,
    lname,
    nationality,
    dob,
    gender,
    mahpassportno,
    passportno,
    doi,
    doe,
    portrait,
    passport,
    reciept,
    date,
    status,
    visa,
    visano,
    visadoe,
    reason
  ) {
    setId(id);
    setFname(fname);
    setMname(mname);
    setLname(lname);
    setNationality(nationality);
    setDob(dob);
    setGender(gender);
    setMahPassportno(mahpassportno);
    setPassportno(passportno);
    setDoi(doi);
    setDoe(doe);
    setPortraitFile(portrait);
    setPassportFile(passport);
    setRecieptFile(reciept);
    setDate(date);
    setStatus(status);
    setReason(reason);
    setVisaFile(visa);
    setVisano(visano);
    setVisadoe(visadoe);
    setModalOpen(true);
  }
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  function recieptUpload() {
    const recieptData = new FormData();
    recieptData.append('id', id);
    recieptData.append('reciept', newRecieptFile);
    dispatch(uploadReciept(recieptData));
    setModalOpen(false);
  }
  return (
    <div className='admin-dashboard-content'>
      <UserHeader current_user={current_user} />
      <hr />
      {loading ? (
        <>
          <LoadingSkeleton />
          <LoadingSkeleton />
          <LoadingSkeleton />
        </>
      ) : (
        <div className='admin-dashboard-main'>
          {visas.length < 1 ? (
            <p className='h4'>You Haven't Filed Any Requests Yet</p>
          ) : (
            <div className='accounts-list'>
              {visas.map((visa, index) => {
                return (
                  <PendingRequestsListItem
                    id={visa.id}
                    fname={visa.fname}
                    mname={visa.mname}
                    lname={visa.lname}
                    nationality={visa.nationality}
                    dob={visa.dob}
                    gender={visa.gender}
                    mahpassportno={visa.mahpassportno}
                    passportno={visa.passportno}
                    doi={visa.doi}
                    doe={visa.doe}
                    portrait={visa.portrait}
                    passport={visa.passport}
                    reciept={visa.reciept}
                    reason={visa.reason}
                    date={visa.date}
                    status={visa.status}
                    visa={visa.visa}
                    visano={visa.visano}
                    visadoe={visa.visadoe}
                    viewDetail={viewDetail}
                    key={index}
                  />
                );
              })}
            </div>
          )}
        </div>
      )}
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalOpen}>
          <div className='w-1/2 absolute translate-y-4 translate-x-1/2 transform bg-gray-100 p-1 rounded-lg'>
            <div className='modal-header text-left px-3'>
              <p className='h4'>Request Details</p>
            </div>
            <div className='modal-body'>
              <TabContext value={tabValue}>
                <TabList
                  value={tabValue}
                  onChange={handleChange}
                  aria-label='lab API tabs example'
                >
                  <Tab label='Details' value={1} />
                  <Tab label='Portrait' value={2} />
                  <Tab label='Passport' value={3} />
                  {status === 'Approved' ? (
                    <Tab label='Reciept' value={4} />
                  ) : null}
                  <Tab label='Visa' value={5} />
                </TabList>
                <TabPanel className='h-96 overflow-auto' value={1}>
                  <Grid
                    container
                    className='w-full text-left items-center'
                    spacing={0}
                  >
                    <Grid lg={6} xs={12}>
                      <p className='h4'>
                        {fname} {mname} {lname}
                      </p>
                    </Grid>
                    <Grid lg={6} xs={12}>
                      <p className='h5'>
                        <strong>Application No. : </strong>
                        {id}
                      </p>
                    </Grid>
                    <Grid lg={6}>
                      <p className='h5'>{nationality}</p>
                    </Grid>
                    <Grid lg={6}>
                      <p className='h5'>
                        <strong>Date of Birth : </strong>
                        {dob}
                      </p>
                    </Grid>
                    <Grid lg={6}>
                      <p className='h5'>
                        <strong></strong>
                        {gender}
                      </p>
                    </Grid>
                    <Grid lg={6}>
                      <p className='h5'>
                        <strong>Passport No. : </strong>
                        {passportno}
                      </p>
                    </Grid>
                    <Grid lg={6}>
                      <p className='h5'>
                        <strong>Mahrum Passport No. : </strong>
                        {mahpassportno}
                      </p>
                    </Grid>
                    <Grid lg={6}>
                      <p className='h5'>
                        <strong>Date of Issue : </strong>
                        {doi}
                      </p>
                    </Grid>
                    <Grid lg={6}>
                      <p className='h5'>
                        <strong>Date of Expiry : </strong>
                        {doe}
                      </p>
                    </Grid>
                    <Grid lg={6}>
                      <p className='h5'>
                        <strong>Date Filed on : </strong>
                        {date}
                      </p>
                    </Grid>
                    <Grid lg={6}>
                      <p className='h5'>
                        <strong>Status : </strong>
                        {status}
                      </p>
                    </Grid>
                    <Grid lg={6}>
                      <p className='h5'>
                        <strong>Reason : </strong>
                        {reason}
                      </p>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel
                  className='flex justify-center items-center h-96 overflow-auto'
                  value={2}
                >
                  <img
                    src={`https://ethioumrah.vohealth.org/client/public/images/${portraitFile}`}
                    alt='Portrait'
                    width='70%'
                  />
                </TabPanel>
                <TabPanel
                  className='flex justify-center items-center h-96 overflow-auto'
                  value={3}
                >
                  <img
                    src={`https://ethioumrah.vohealth.org/client/public/images/${passportFile}`}
                    alt='Passport'
                    width='70%'
                  />
                </TabPanel>
                {status === 'Approved' ? (
                  <TabPanel
                    className='flex justify-center items-center h-96 overflow-auto'
                    value={4}
                  >
                    {recieptFile ? (
                      <img
                        src={`https://ethioumrah.vohealth.org/client/public/images/${recieptFile}`}
                        alt='Reciept'
                        width='70%'
                      />
                    ) : (
                      <Grid item md={6} xs={12}>
                        <div className='admin-dashboard-main-item'>
                          <div className='file-upload-container'>
                            <div className='upload-icon-container p-3 w-fit text-center items-center flex justify-center mx-auto rounded-full border border-dashed border-gray-400'>
                              {recieptFile !== null ? (
                                <CheckRoundedIcon />
                              ) : (
                                <ReceiptLongIcon />
                              )}
                            </div>
                            <Box
                              sx={{
                                color: 'text.secondary',
                                fontSize: 18,
                                marginX: 'auto',
                              }}
                            >
                              Upload Reciept
                            </Box>
                            <Box>
                              <div className='file-upload-button-container'>
                                <label htmlFor='reciept-file'>
                                  <Input
                                    accept='image/*'
                                    id='reciept-file'
                                    name='reciept'
                                    type='file'
                                    onChange={(e) =>
                                      setNewRecieptFile(e.target.files[0])
                                    }
                                  />
                                  <Button
                                    sx={{ borderRadius: '2em' }}
                                    variant='contained'
                                    component='span'
                                    disableElevation
                                  >
                                    Choose File
                                  </Button>
                                </label>
                              </div>
                            </Box>
                            <Button
                              sx={{ borderRadius: '2em', marginY: '1em' }}
                              variant='contained'
                              color='success'
                              component='span'
                              disabled={!newRecieptFile}
                              onClick={recieptUpload}
                            >
                              Upload
                            </Button>
                          </div>
                        </div>
                      </Grid>
                    )}
                  </TabPanel>
                ) : null}
                <TabPanel
                  className='flex justify-start h-96 overflow-auto'
                  value={5}
                >
                  {visano ? (
                    <Grid container className='w-full text-center' spacing={0}>
                      <Grid lg={6}>
                        <Grid lg={12}>
                          <p className='h5'>
                            <strong>Visa No. : </strong>
                            {visano}
                          </p>
                        </Grid>
                        <Grid lg={12}>
                          <p className='h5'>
                            <strong>Visa Expiry Date : </strong>
                            {visadoe}
                          </p>
                        </Grid>
                      </Grid>
                      <Grid lg={6}>
                        <StyledTooltip title='Download Visa' placement='bottom'>
                          <a href={`/uploads/${visaFile}`}>
                            <IconButton
                              color='primary'
                              aria-label='upload picture'
                              component='span'
                              type='submit'
                            >
                              <FileDownloadOutlinedIcon />
                            </IconButton>
                          </a>
                        </StyledTooltip>
                      </Grid>
                    </Grid>
                  ) : (
                    <p className='h4 text-center'>No Visa Details to Show</p>
                  )}
                </TabPanel>
              </TabContext>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default UserDashboardContent;
