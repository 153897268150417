import axios from 'axios';
import {
  GET_ERRORS,
  SET_CURRENT_USER,
  LOGOUT,
} from '../actionTypes/actionTypes';
import setAuthToken from '../util/setAuthToken';
import jwt_decode from 'jwt-decode';

export const userLogin = (userData) => (dispatch) => {
  axios
    .post('https://ethioumrah.vohealth.org/api/user/user-login/', userData)
    .then((res) => {
      const { token } = res.data;
      // set token to local storage
      localStorage.setItem('jwtToken', token);
      const userToken = localStorage.getItem('jwtToken');
      // Set token to auth header
      setAuthToken(userToken);
      // decode token to get user data
      const decoded = jwt_decode(userToken);
      const loginTime = localStorage.getItem('loginTime');
      localStorage.setItem('loginTime', new Date().getTime());
      const loginData = {
        decoded: decoded,
        loginTime: loginTime,
      };
      // Set cerrent user
      dispatch(setCurrentUser(loginData));
    })
    .catch((err) => {
      let message =
        typeof err.response !== 'undefined' ? err.response.data : null;
      dispatch({
        type: GET_ERRORS,
        payload: message,
      });
    });
};

export const adminLogin = (userData) => (dispatch) => {
  axios
    .post('https://ethioumrah.vohealth.org/api/admin/admin-login', userData)
    .then((res) => {
      const { token } = res.data;
      // set token to local storage
      localStorage.setItem('jwtToken', token);
      // Set token to auth header
      setAuthToken(token);
      // decode token to get user data
      const decoded = jwt_decode(token);
      // Set cerrent user
      const loginTime = localStorage.getItem('loginTime');
      localStorage.setItem('loginTime', new Date().getTime());
      const loginData = {
        decoded: decoded,
        loginTime: loginTime,
      };
      dispatch(setCurrentUser(loginData));
    })
    .catch((err) => {
      let message =
        typeof err.response !== 'undefined' ? err.response.data : null;
      dispatch({
        type: GET_ERRORS,
        payload: message,
      });
    });
};

export const logout = () => (dispatch) => {
  localStorage.removeItem('jwtToken');
  setAuthToken(false);
  dispatch({
    type: LOGOUT,
    payload: {
      decoded: {},
      loginTime: null,
      isAuthenticated: false,
    },
  });
};

// Set current user
export const setCurrentUser = (loginData) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_USER,
    payload: loginData,
  });
};
